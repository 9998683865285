import DocumentService from '../services/document.service';

const initialState = {
    document: null,
    documents: [],
    downLoading: false,
    upLoading: false,
    document_name: null
}
export const documents = {
    namespaced: true,
    state: initialState,
    actions: {
        async getDocuments({ commit }) {
            let allDocuments = await DocumentService.getAllDocuments();
            allDocuments.data.documents.forEach(document => {
                if (document.ext === "pdf"){
                    document.logo_source = "pdf-96.png"
                }
                else if(document.ext ==="csv"){
                    document.logo_source = "csv-96.png"
                }
                else if(['jpg', 'jpeg'].includes(document.ext)){
                    document.logo_source = "jpeg-64.png"
                }
                else if(document.ext==="png"){
                    document.logo_source = "png-96.png"
                }
                else{
                    document.logo_source = "word-96.png"
                }
            })
            commit('populateDocuments', allDocuments.data.documents);
        },
        async download({commit}, file_id){
            await DocumentService.download(file_id);
            commit('populateDocumentDetails', 'download')
        },
        async delete({commit}, file_id){
            await DocumentService.delete(file_id)
            commit('populateDocumentDetails', 'delete')
        },
        async select({commit}, document_name){
            commit('populateDocumentName', document_name)
        },
        async setErrorContent({commit}, content){
            commit('populateDocumentDetails', content)
        }
    },
    mutations: {
        populateDocuments(state, documents) {
            state.documents = documents;
        },
        populateDocumentDetails(state, document){
            state.document = document
        },
        populateDocumentName(state, document_name){
            state.document_name = document_name
        }
    },
}