<template>
  <div class="about">
    <h1 class=app-h1>About Our Church</h1>
    <p>The congregation was originally formed as Cardonald United Free Church by the Glasgow United Free Church Presbytery 1908. Under the 1929 Act of Union the transition was made to Hillington Park Church of Scotland.
    </p>
    <p>Our minister is Rev Dave Sutherland</p>
    <p>Dave became our 9th minister, succeeding Rev Robert Craig, who moved to Breich Valley Parish Church in October 2020.</p>
 </div>
</template>

<script>
export default {
  name: 'AboutView'
};
</script>

<style>
.about{
    font-family: "Roboto Slab";
    font-size: x-large;
    background-color: rgba(255,255,255, 0.9);
    padding:1rem;
    border-radius: 1rem;
    box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
}

.about:hover{
  background-color: rgb(250, 250, 250, 0.95);
  box-shadow: 0.4rem 0.6rem 0.6rem #1816168c;
}
</style>