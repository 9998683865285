const initialState = {
    cookiesAccepted: false,
}
export const cookies = {
    namespaced: true,
    state: initialState,
    actions: {
        acceptAndCloseCookies({ commit }) {
            commit('acceptCookies');
          },
    },
    mutations: {
        acceptCookies(state) {
          state.cookiesAccepted = true;
        },
    },
}