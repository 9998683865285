<template>
  <div id="app">
    <nav v-if="windowWidth < 768" class="navbar navbar-expand navbar-light">
      <div class="navbar-nav mr-auto">
        <li class="nav-item">
          <router-link to="/home" class="nav-link">
            <font-awesome-icon icon="home" class="home-icon" />
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/mobile-menu" class="nav-link">
            <font-awesome-icon icon="fa-solid fa-bars" class="align-icon" />
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/events" class="nav-link">
            <font-awesome-icon icon="fa-calendar" class="doc-icon" />
          </router-link>
        </li>
        <!-- <li class="nav-item">
          <router-link to="/donate" class="nav-link">
            <font-awesome-icon icon="fa-donate" class="donate-icon" />
          </router-link>
        </li> -->
        <div v-if="(isSuperAdmin || isAdmin) && currentUser && isLoggedIn" class="navbar-nav ml-auto">
          <!-- <li class="nav-item">
            <router-link to="/organisation-dashboard" class="nav-link">
              <font-awesome-icon icon="sign-in-alt" class="admin-icon" />
              <span v-if="windowWidth > 600">Admin Dashboard</span>
            </router-link>
          </li> -->
        </div>
        <div v-if="currentUser && isLoggedIn" class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link to="/documents" class="nav-link">
              <font-awesome-icon icon="fa-file" class="doc-icon" />
              <span v-if="windowWidth > 600">Documents</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/church-events" class="nav-link">
              <font-awesome-icon icon="fa-calendar" class="event-icon" />
              <span v-if="windowWidth > 600">Events</span>
            </router-link>
          </li>
        </div>
        <div v-if="currentUser && isLoggedIn" class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link to="/profile" class="nav-link">
              <font-awesome-icon icon="user" />
              {{ currentUser.email }}
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click.prevent="logOut">
              <font-awesome-icon icon="sign-out-alt" />
            </a>
          </li>
        </div>
        <div v-else>
          <li class="nav-item">
            <router-link to="/login" class="nav-link">
              <font-awesome-icon icon="sign-in-alt" class="members-icon" />
            </router-link>
          </li>
        </div>
      </div>
    </nav>
    <nav v-else class="navbar navbar-expand navbar-light" :class="this.viewingDropdown? 'viewing-dropdown':''">
      <div class="navbar-nav mr-auto">
        <li class="nav-item">
          <router-link to="/home" class="nav-link">
            <font-awesome-icon icon="home" class="home-icon" />
          </router-link>
        </li>
        <li class="nav-item" v-if="windowWidth > 768">
          <router-link to="/events" class="nav-link">
            <font-awesome-icon icon="fa-calendar" class="event-icon" />
            <span>Events</span>
          </router-link>
        </li>
        <!-- <li class="nav-item" v-if="windowWidth > 768">
          <router-link to="/donate" class="nav-link">
            <font-awesome-icon icon="fa-donate" class="donate-icon" />
            <span>Donate</span>
          </router-link>
        </li> -->
        <li v-if="windowWidth > 768" @click="toggleViewDropdown(!this.viewingDropdown)" class="nav-item">
          <span class="nav-link main-link">
            <font-awesome-icon icon="fa-church"/>  
            About the Church
          </span>
          <DropdownView v-if="this.viewingDropdown"/>
        </li>
        <!-- <li v-if="windowWidth > 768" class="nav-item">
          <router-link @click="toggleViewDropdown" to="/about" class="nav-link">
            About our Church
          </router-link>
        </li>
        <li v-if="windowWidth > 768">
          <router-link @click="toggleViewDropdown" to="/what-we-believe" class="nav-link">
            What We Believe
          </router-link>
        </li>
        <li v-if="windowWidth > 768">
          <router-link @click="toggleViewDropdown" to="/our-minister" class="nav-link">
            Our Minister
          </router-link>
        </li>
        <li v-if="windowWidth > 768">
          <router-link @click="toggleViewDropdown" to="/church-contacts" class="nav-link">
            Church Contacts
          </router-link>
        </li> -->
      </div>
      <div v-if="currentUser && isLoggedIn" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/documents" class="nav-link">
            <font-awesome-icon icon="fa-file" class="doc-icon" /> Documents
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/church-events" class="nav-link">
            <font-awesome-icon icon="fa-calendar" />
            <span v-if="windowWidth > 600">Manage Events</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/whats-new" class="nav-link">
            <font-awesome-icon icon="air-freshener" class="admin-icon"/>
            <span v-if="windowWidth > 600">What's New</span>
          </router-link>
        </li>
      </div>
      <div v-if="!currentUser" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/login" class="nav-link">
            <font-awesome-icon icon="sign-in-alt" class="members-icon" /> Members Portal
          </router-link>
        </li>
      </div>
      <div v-if="currentUser && isLoggedIn" class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/profile" class="nav-link">
            <font-awesome-icon icon="user" />
            {{ currentUser.email }}
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="logOut">
            <font-awesome-icon icon="sign-out-alt" /> LogOut
          </a>
        </li>
      </div>
    </nav>
    <div class="app-container">
      <router-view />
    </div>
    <footer class="cookies" v-if="!cookiesAccepted">
      <CookiesInfoView />
    </footer>
  </div>
</template>
<script>
import authService from './services/auth.service';
import DropdownView from './views/Public/DropdownView.vue'
import CookiesInfoView from './views/Public/CookiesInfoView.vue';
export default {
  components:{DropdownView, CookiesInfoView},
  computed: {
    windowWidth() {
      return this.$store.state.resizer.windowWidth;
    },
    basedOnWindowWidth() {
      if (this.windowWidth > 768) {
        return true;
      } return false;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    cookiesAccepted(){
      return this.$store.state.cookies.cookiesAccepted;
    },
    isSuperAdmin() {
      if (this.currentUser && this.currentUser['role']) {
        return this.currentUser['role'] === "Super Administrator"
      }
      return false
    },
    isAdmin() {
      if (this.currentUser && this.currentUser['role']) {
        return this.currentUser['role'] === "Administrator"
      }
      return false
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }
      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }
      return false;
    }
  },
  data(){
    return {
      viewingDropdown: false,
    }
  },
  mounted() {
    window.addEventListener(
      'resize',
      this.resizeMethod)
  },
  methods: {
    async isLoggedIn() {
      return await authService.isLoggedIn()
    },
    toggleViewDropdown(toggleValue){
      this.viewingDropdown = toggleValue
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    resizeMethod() {
      this.$store.commit('resizer/setWindowWidth')
    },
    sayHello(){
      console.log('hi')
    }
  }
};
</script>
<style>
@font-face {
  font-family: 'Roboto-Slab';
  src: url("assets/RobotoSlab-VariableFont_wght.ttf") format("truetype");
}

/* Minimum width */
@media (min-width: 1000px) {
  body {
    background-image: url("assets/hpc-logo.jpg");
    background-size: 50rem;
    /* width: 500rem; */
    /* height: 300rem; */
    font-family: 'Roboto-Slab';
    width: 100%;
  }
}

/* Minimum width */
@media (400px < width < 1000px) {
  body {
    background-image: url("assets/hpc-logo.jpg");
    background-size: 35rem;
    /* width: 500rem; */
    /* height: 300rem; */
    font-family: 'Roboto-Slab';
  }
}

/* Maximum width */
@media (max-width: 400px) {
  body {
    background-image: url("assets/hpc-logo.jpg");
    background-size: 15rem;
    /* width: 500rem; */
    /* height: 300rem; */
    font-family: 'Roboto-Slab';
  }
}

.app-h1 {
  text-align: center;
  background: rgba(13, 13, 65, 0.9);
  border-radius: 0.25rem;
  color: #f0f0f0;
}

#home-link {
  display: flex;
  font-size: 2rem;
  font-family: 'Roboto-Slab';
  font-style: italic;
}

.bt-icon {
  background: url('./assets/logo-icon.png');
  height: 2rem;
  width: 2rem;
  display: block;
  border-radius: 1rem;
  margin-top: 0.5rem;
}

.app-container {
  margin: auto;
  padding-top: 5rem;
  font-family: 'Roboto-Slab';
  font-size: large;
  width: 90%;
}

.nav-link {
  cursor: pointer;
  display: inline;
}

.bt-footer {
  position: fixed;
  padding: 10px 10px 0px 10px;
  bottom: 0;
  width: 100%;
  height: 40px;
  background: #4F5D75;
}

.bt-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  cursor: pointer;
}

.bt-file-label {
  background-color: midnightblue;
  color: #f0f0f0;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
  font-family: "Roboto Slab";
}

.bt-file-label:hover {
  background-color: rgb(6, 6, 75);
  box-shadow: 0.4rem 0.5rem 0.5rem #201f1f8c;
}

.bt-file-label:active {
  background-color: rgb(13, 4, 53);
  box-shadow: 0.6rem 0.6rem 0.6rem #0808088c;
}

.bt-input {
  font-family: "Roboto Slab";
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 1rem;
  border-radius: 1rem;
  font-size: large;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(30, 33, 213, 0.9);
}

.bt-input:hover {
  background-color: rgb(65, 65, 114);
  color: white
}

.bt-input:active {
  background-color: midnightblue;
  color: white
}

.nav-item {
  cursor: pointer;
}

.navbar {
  position: fixed !important;
  /* Set the navbar to fixed position */
  top: 0;
  /* Position the navbar at the top of the page */
  width: 100%;
  white-space: nowrap;
  overflow: auto;
}

.router-link-active {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar a {
  margin-left: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0.05rem 0.05rem 0.05rem rgba(0, 0, 0, 0.1);
  font-family: 'Roboto-Slab';
  font-size: x-large;
}

.navbar a:hover {
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.2rem 0.3rem #888888;
}

.navbar a:active {
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
}

table {
  width: 100%;
  text-align: center;
  border-radius: 1rem;
  box-shadow: 0.25rem 0.25rem 0.25rem #302d3b8c;
  line-height: 5;
  font-size: larger
}

th {
  height: 5rem;
  font-size: 1.5rem;
}

td {
  padding-right: 1rem;
}


tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.9);
}

tr:nth-child(even),
th {
  background-color: rgba(223, 223, 223, 0.95);
}

td:hover {
  color: white;
  cursor: pointer;
  background-color: rgb(49, 29, 95);
}

.home-icon {
  color: blue
}

.admin-icon {
  color: green
}

.members-icon {
  color: blue
}

.doc-icon {
  color: orchid;
}

.donate-icon{
  color: orange;
}

.event-icon {
  color: red;
}

.home-icon:hover {
  color: rgba(0, 0, 255, 0.4)
}

.admin-icon:hover {
  color: rgba(0, 128, 0, 0.4)
}

.members-icon:hover {
  color: rgba(0, 0, 255, 0.4)
}

.main-link{
  font-size: x-large;
}

.doc-icon:hover {
  color: rgba(255, 0, 0, 0.4);
}

.align-icon {
  color: rgba(0, 255, 0, 0.8);
}

.align-icon:hover {
  color: rgba(0, 255, 0, 0.4);
}

.cookies{
  bottom:1rem;
  position:fixed;
  width: 100%;
}

.viewing-dropdown{
}
</style>