import axios from 'axios';
import authHeader from './auth-header';

const PARTICIPANTS_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/participants';

class ParticipantService {
  async delete(eventId, participantEmail){
    await axios.delete(PARTICIPANTS_API_URL + '/delete/' + eventId + '/' + participantEmail, { headers: authHeader() });
  }
  getEventParticipants(eventCode) {
    return axios.get(PARTICIPANTS_API_URL + '/' + eventCode, { headers: authHeader() });
  }
  updateParticipant(participant){
    return axios.post(PARTICIPANTS_API_URL, {
      names: participant.names,
      email: participant.email,
      age: participant.age? participant.age: "",
      address: participant.address? participant.address: "",
      postcode: participant.postcode? participant.postcode: "",
      phone: participant.phone? participant.phone: "",
      diet: participant.diet? participant.diet: "",
      inform: participant.inform? participant.inform: "",
      photo_consent: participant.photo_consent? false: true,
      event: participant.event
    }, { headers: authHeader() })
  }
  signUpForEvent(participant){
    return axios.post(PARTICIPANTS_API_URL, {
      names: participant.names,
      email: participant.email,
      age: participant.age? participant.age: "",
      address: participant.address? participant.address: "",
      postcode: participant.postcode? participant.postcode: "",
      phone: participant.phone? participant.phone: "",
      diet: participant.diet? participant.diet: "",
      inform: participant.inform? participant.inform: "",
      photo_consent: participant.photo_consent? false: true,
      event: participant.event,
      organisation: 'Hillington Park Church'
    })
  }
}
export default new ParticipantService();
