import ParticipantService from '../services/participant.service';

const initialState = {
    participants: [],
    participant: null,
    registered: false
}
export const participants = {
    namespaced: true,
    state: initialState,
    actions: {
        async getEventParticipants({ commit }, eventId) {
            let eventParticipants = await ParticipantService.getEventParticipants(eventId);
            commit('populateParticipants', eventParticipants.data.participants);
          },
          async signUpParticipantForEvent({commit}, participant){
            await ParticipantService.signUpForEvent(participant)
            commit('registerParticipant')
          },
    },
    mutations: {
        registerParticipant(state) {
          state.registered = true;
        },
        populateParticipants(state, participants){
          state.participants = participants
        }
    },
}