import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/organisation-documents';

class documentService {
  async getAllDocuments() {
    return await axios.get(API_URL + '/hpc', { headers: authHeader() });
  }
  async upload(files) {
    var formData = new FormData();
    formData.append('file', files);
    const response = await axios.post(API_URL + '/hpc', formData, { headers: authHeader() });
    return response.data;
  }
  async download(file){
    let file_id = file.document_id
    let response = await axios({
      url: API_URL + '/download/' + file_id,
      headers: authHeader(),
      method: 'GET',
      responseType: 'blob', // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.filename);
    document.body.appendChild(link);
    link.click();
  }
  async delete(file){
    let file_id = file.document_id
    await axios.delete(API_URL + '/delete/' + file_id, { headers: authHeader() });
  }
}
export default new documentService();