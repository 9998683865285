import UserService from '../services/user.service';
import AuthService from '@/services/auth.service';

const initialState = {
    users: [],
    reset: false
}
export const user = {
    namespaced: true,
    state: initialState,
    actions: {
        async getUsers({ commit }) {
            let allUsers = await UserService.getUsers();
            commit('populateUsers', allUsers.data.users);
          },
          async sendEmail({commit}, userEmail){
            await UserService.sendEmail(userEmail)
            commit('passwordReset')
          },
          async remove(user){
            await AuthService.delete(user)
          }
    },
    mutations: {
        populateUsers(state, users) {
            state.users = users;
          },
          passwordReset(state) {
            state.reset = true
          }
    },
}