<template>
    <footer class="cookies-info">
        <h1 class=app-h1>Cookies</h1>
        <div>
            <hr />
            <p>This website uses limited cookies for functionality services.</p>
            <p>There are no advertisers using this site.</p>
            <button class="btn btn-primary" @click="acceptCookies">Accept</button>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'CookiesInfoView',
    methods: {
        acceptCookies(){
            this.$store.dispatch('cookies/acceptAndCloseCookies')
    },
    }
};
</script>

<style>
.cookies-info {
    font-family: "Roboto Slab";
    font-size: x-large;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
}

.cookies-info:hover {
    background-color: rgb(250, 250, 250, 0.95);
    box-shadow: 0.4rem 0.6rem 0.6rem #1816168c;
}
</style>