<template>
    <div class="main-menu">
      <div class>
        <li class="main-item">
          <router-link to="/about" class="main-link">
            About our Church
          </router-link>
        </li>
        <li class="main-item">
          <router-link to="/what-we-believe" class="main-link">
             What We Believe
          </router-link>
        </li>
        <li class="main-item">
          <router-link to="/our-minister" class="main-link">
            Our Minister
          </router-link>
        </li>
        <li class="main-item">
          <router-link to="/church-contacts" class="main-link">
            Church Contacts
          </router-link>
        </li>
      </div>
   </div>
  </template>
  
  <script>
  export default {
    name: 'DropdownView'
  };
  </script>
  
<style scoped>
.main-menu{
    position:fixed;
    font-size: x-large;  
    background-color: rgba(255, 255, 255, 0.9);
    border:0.1rem solid rgba(9, 10, 29, 0.5);
    border-radius: 1rem;
    box-sizing: border-box;
    margin:0.2rem;
}
.main-link{
    text-decoration: none;  
    font-size: x-large;  
}

.main-item a{
    text-decoration: none;
    color:black;
}
</style>