<template>
  <div class="home">
    <div class="hpc-headers">
      <h1 class="app-h1">Hillington Park Church</h1>
      <h2 class="welcome-h2">Welcome!</h2>
    </div>
    <!-- <div class="hpc-event todays-event" v-if="todaysEvent">
      Join us for todays event!
      <router-link :to="'/signup/' + todaysEvent.id" class="nav-link">
        {{ todaysEvent.name }} - {{ todaysEvent.eventdate }}
      </router-link>    
    </div>
    <div class="hpc-event next-event" v-else-if="nextEvent">
      Join us for our next event!
      <router-link :to="'/signup/' + nextEvent.id" class="nav-link">
        {{ nextEvent.name }} - {{ nextEvent.eventdate }}
        <img class="small" src="../assets/Messy-Church-logo.jpg"/>
      </router-link>
    </div> -->
    <div class="hpc-alpha">
      <router-link to="events">
        Join us for Alpha and our many other events!
      </router-link>
    </div>
    <div class="main-content">
      <p>
        Hillington Park Church sits on Berryknowes Road next to the Morrisons store.
        <strong>Our Sunday services are at 11am and 6.30pm</strong> which everyone is warmly welcome to attend.
      </p>
      <p>
        We have a Sunday club for children that meets during the service and also a crèche for babies up to 3 years old.
        We are also host to a number of clubs during the week including Zumba, Art, Girls / Boys Brigade
      </p>
      <!-- <LocationMap/> -->
    </div>
    <div class="contact">
      <font-awesome-icon icon="phone" /> 0141 463 3203
    </div>
    <!-- <div class="image-footer">
      <img :class="windowWidth>768?'hpc-img-wide':'hpc-img-small'" src="../assets/hpc1.jpg"/>
    </div> -->
  </div>
</template>
<script>

//import LocationMap from '../components/Maps/LocationMap.vue'
export default {
  name: "HomeView",
  //components:{LocationMap},
  computed: {
    windowWidth() {
      return this.$store.state.resizer.windowWidth;
    },
    todaysDate() {
      const dateObj = new Date();
      const month = dateObj.getUTCMonth() + 1; // months from 1-12
      const day = dateObj.getUTCDate();
      const year = dateObj.getUTCFullYear();
      // Using padded values, so that 2023/1/7 becomes 2023/01/07
      const pMonth = month.toString().padStart(2, "0");
      const pDay = day.toString().padStart(2, "0");
      const newPaddedDate = `${year}-${pMonth}-${pDay}`;
      return newPaddedDate
    },
    todaysEvent(){
      return this.$store.state.events.events.find(event => Date.parse(event.eventdate) === Date.parse(this.todaysDate))      
    },
    nextEvent() {
      return this.$store.state.events.events.find(event => Date.parse(event.eventdate) > Date.parse(this.todaysDate))
    },
    events() {
      return this.$store.state.events.events.filter(event => Date.parse(event.eventdate) > Date.parse(this.todaysDate))
    },
  },
  data() {
    return {
      content: "Welcome to Hillington Park Church",
    };
  },
  async mounted() {
    this.$store.dispatch("events/getEvents")
    console.log(this.todaysDate)
    // this.$store.dispatch("events/getNextEvent")
  },
};
</script>

<style scoped>
.home {
  font-family: "Roboto Slab";
  font-size: x-large;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
}

.home:hover {
  background-color: rgb(250, 250, 250, 0.9);
  box-shadow: 0.4rem 0.6rem 0.6rem #1816168c;
}

.image-footer-wide {
  /* bring your own prefixes */
  border: 1rem solid black;
  /* box-shadow: 0.1rem 0.1rem rgba(255,255,255,0.5); */
  border-radius: 1rem;
  width: 50rem;
}

.hpc-img-wide {
  width: 10rem;
  box-shadow: 1rem;
  border-radius: 1rem;
}

.hpc-img-small {
  width: 15rem;
  padding: 1rem;
  border-radius: 1rem;
}

.hpc-img-wide:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
}

.hpc-img-small:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
}

.messy-event-link {
  box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
  border-radius: 1rem;
}

/* 
.messy-narrow{
} */

.messy-wide {
  display: flex;
}

.welcome-h2 {
  text-align: center;
}

.welcome-h3 {
  text-align: center;
}

.hpc-pic {
  border: 1rem solid black;
  border-radius: 1rem;
  width: 95%;
}

.hpc-pic:hover {
  border: 1rem solid rgba(0, 0, 0, 0.7);
  border-radius: 1rem;
  box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
}

.hpc-pic:active {
  border: 1rem solid rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  box-shadow: 0.2rem 0.3rem 0.3rem #3633338c;
}

.messy-link {
  border: 0.5rem solid rgb(165, 12, 12);
  border-radius: 1rem;
  width: 60%;
  min-width: 12rem;
}

.messy-link:hover {
  border: 0.5rem solid rgba(165, 12, 12, 0.7);
  box-shadow: 0.3rem 0.4rem 0.4rem #ff17178c;
  opacity: 0.9;
}

.messy-link:active {
  border: 0.5rem solid rgba(165, 12, 12, 0.3);
  box-shadow: 0.4rem 0.6rem 0.6rem #ff17178c;
  opacity: 0.5;
}

.hpc-map {
  min-height: 20rem;
}

.pic-map {
  min-width: 10rem;
  border: 0.5rem solid rgba(0, 0, 0, 0.103);
  border-radius: 1rem;
  box-shadow: 0.2rem 0.3rem 0.3rem #3633338c;
}

.pic-map:hover {
  border: 0.5rem solid rgba(0, 0, 0, 0.5);
  box-shadow: 0.2rem 0.3rem 0.3rem #1f1d1d8c;
  opacity: 0.9;
}

.pic-map:active {
  border: 0.5rem solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  box-shadow: 0.4rem 0.6rem 0.6rem #0808088c;
  opacity: 0.5;
}

.hpc-pic-wide {
  display: inline-flex;
}

.messy-inline {
  display: inline-flex;
}

.map-wide {
  height: 90%;
  transform: translateY(25%);
  border: 0.1rem solid rgba(117, 113, 113, 0.5)
}

</style>
