<template>
    <div v-if="visible" class="notification">
      <div class="notification-content">
        <p>{{ message }}</p>
        <button @click="closeNotification" class="btn btn-link">Close</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotificationView',
    props: {
      message: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        visible: true
      };
    },
    methods: {
      closeNotification() {
        this.visible = false;
        this.$emit('closeNotification');
      }
    }
  };
  </script>
  
  <style scoped>
  .notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    z-index: 1000;
  }
  
  .notification-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .btn-link {
    color: #721c24;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  </style>