import axios from 'axios';
import authHeader from './auth-header';

const EVENTS_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/events/hpc';
const IMAGES_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/events/images/hpc';
const IMAGE_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/events/image/hpc'
const QR_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/events/qr/hpc'


class EventService {
  async delete(eventModel){
    await axios.delete(EVENTS_API_URL + '/delete/' + eventModel, { headers: authHeader() });
  }
  getEvents() {
    return axios.get(EVENTS_API_URL);
  }
  getEvent(event){
    return axios.get(EVENTS_API_URL + '/' + event);
  }
  getEventImages(event){
    return axios.get(IMAGES_API_URL + '/' + event);
  }
  getNextEvent(){
    return axios.get(EVENTS_API_URL + '/next-event');
  }
  async createEvent(eventModel){
    var formData = new FormData()
    let website = 'https://www.hillingtonparkchurch.co.uk/signup'
    formData.append('name',eventModel.name)
    formData.append('description', eventModel.description)
    formData.append('address', eventModel.address)
    formData.append('postcode', eventModel.postcode)
    formData.append('eventdate', eventModel.date)
    formData.append('website', eventModel.website?eventModel.website:website)
    formData.append('organisation', "hpc")
    formData.append('file', eventModel.file);
    formData.append('show_qr', eventModel.show_qr);
    return await axios.post(EVENTS_API_URL, formData, { headers: authHeader()})
  }
  async updateEvent(eventModel){
    var formData = new FormData()
    formData.append('name',eventModel.name)
    formData.append('description', eventModel.description)
    formData.append('address', eventModel.address)
    formData.append('postcode', eventModel.postcode)
    formData.append('eventdate', eventModel.date)
    formData.append('website', eventModel.website)
    formData.append('organisation', "hpc")
    formData.append('file', eventModel.file);
    formData.append('show_qr', eventModel.show_qr);
    return await axios.put(EVENTS_API_URL , formData, { headers: authHeader()})
  }
  async uploadEventImage(image, eventId){
    var formData = new FormData()
    formData.append('file', image)
    return await axios.post(IMAGE_API_URL + '/' + eventId, formData, { headers: authHeader()})  
  }
  async generateQRCode(event){
    return await axios.post(QR_API_URL, {"instruction": "update", "event_id":event.id}, { headers: authHeader()})
  }
  async deleteQR(event){
    var formData = new FormData()
    formData.append('instruction', "delete")
    formData.append('event_id', event.id)
    return await axios.put(QR_API_URL, formData, { headers: authHeader()} )
  }
  async showQR(event){
    var formData = new FormData()
    formData.append('instruction', "show")
    formData.append('event_id', event.id)
    return await axios.put(QR_API_URL, formData, { headers: authHeader()} )
  }
  async hideQR(event){
    var formData = new FormData()
    formData.append('instruction', "hide")
    formData.append('event_id', event.id)
    return await axios.put(QR_API_URL, formData, { headers: authHeader()} )
  }
}
export default new EventService();
