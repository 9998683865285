<template>
  <div class="login-container">
    <div class="card card-container">
      <form @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" v-model="email" class="form-control" required />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" v-model="password" class="form-control" required />
        </div>
        <button type="submit" class="btn btn-primary btn-block" :disabled="loading">
          <span v-if="loading" class="spinner-border spinner-border-sm"></span>
          <span v-else>Login</span>
        </button>
      </form>
      <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
      <!-- <router-link to="/password-reset" class="d-block mt-3">Forgot your password?</router-link> -->
      <NotificationView v-if="notificationMessage" :message="notificationMessage" @closeNotification="notificationMessage = ''" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import NotificationView from '@/components/NotificationView.vue';

export default {
  components: {
    NotificationView
  },
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      loading: false,
      notificationMessage: ''
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    async handleLogin() {
      this.loading = true;
      try {
        await this.login({
          email: this.email,
          password: this.password,
          organisation_uri: process.env.VUE_APP_ORGANISATION_URI
        });
        this.$router.push('/profile');
      } catch (error) {
        this.errorMessage = error.response.data.error || 'An error occurred while logging in';
        this.notificationMessage = this.errorMessage;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.card-container {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.btn-block {
  width: 100%;
}

.alert {
  margin-top: 15px;
}
</style>