// ui/src/services/auth.service.js

import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  login(user) {
    return axios
      .put(`${API_URL}/organisation-auth/authenticate-user`, {
        email: user.email,
        password: user.password,
        organisation_uri: user.organisation_uri
      })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(`${API_URL}/organisation-auth/authenticate-user`, {
      email: user.email,
      password: user.password,
      organisation_uri: user.organisationUri,
      first_name: user.firstName,
      last_name: user.lastName
    });
  }
}

export default new AuthService();